<template>
  <div class="wrap">
    <p class="title">视频设置</p>
    <a-form v-bind="formItemLayout">
      <a-form-item label="默认分辨率">
        <a-select v-model:value="distinguishability" placeholder="请选择默认分辨率">
          <a-select-option v-for="item in resolutionList" :value="item.value" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="默认分享时效">
        <div style="display: flex">
          <a-input-number v-model:value="shareTime" :min="1" placeholder="请选择默认分享时效"
            :formatter="value => value.replace(/\^(0+)|[^\d]+/g, '')"
            :parser="value => value.replace(/\^(0+)|[^\d]+/g, '')"
          />
          <a-select v-model:value="shareTimeType" style="width: 80px">
              <a-select-option v-for="item in unitList" :value="item.value" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
        </div>
        
      </a-form-item>
      <a-form-item label="默认推送时效">

        <div style="display: flex">
          <a-input-number v-model:value="pushTime" :min="1" placeholder="请选择默认推送时效"
            :formatter="value => value.replace(/\^(0+)|[^\d]+/g, '')"
            :parser="value => value.replace(/\^(0+)|[^\d]+/g, '')"
          />
          <a-select v-model:value="pushTimeType" style="width: 80px">
              <a-select-option v-for="item in unitList" :value="item.value" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
        </div>

        
      </a-form-item>
      <a-form-item label="直播标签">
        <a-button @click="jumpToTagManage">标签管理</a-button>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 10 }">
        <a-button type="primary" @click="setVideoSetting">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import constant from '@/utils/common';
import { useRouter } from 'vue-router';
import api from '@/services';

export default defineComponent({
  setup() {
    const router = useRouter();
    const unitList = reactive({ ...constant.unitList });
    const resolutionList = reactive({ ...constant.resolutionList });

    const formItemLayout = reactive({
      labelCol: { span: 4, offset: 3 },
      wrapperCol: { span: 10 }
    });
    // 表单状态
    const formState = reactive({
      distinguishability: 1, // 分辨率 type
      shareTime: '', // 分享时效
      shareTimeType: 1, // 分享时效时间单位 type
      pushTime: '', // 推送时效时间
      pushTimeType: 1 // 推送时效时间单位 type
    });

    onMounted(() => {
      getVideoSetting();
    });

    const getVideoSetting = async () => {
      const { success, data } = await api.getVideoSetting();
      if (success) {
        Object.keys(formState).map(item => {
          formState[item] = data[item];
        });
      }
    };

    // 保存设置
    const setVideoSetting = async () => {
      let params = {
        ...formState
      };
      const { success } = await api.setVideoSetting(params);
      if (success) {
        message.success('设置成功！');
      }
    };

    // 跳转到标签管理页面
    const jumpToTagManage = () => {
      router.push('/admin/business-manage/tag-manage');
    };

    return {
      unitList,
      resolutionList,
      formItemLayout,
      ...toRefs(formState),
      setVideoSetting,
      getVideoSetting,
      jumpToTagManage
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
  }
}
:deep(.ant-input-number-handler-wrap) {
  display: none
}
.ant-input-number {
  flex: 1;
}
</style>
